import type { FC } from 'react';
import React, { memo, useContext, useCallback } from 'react';
import { defineMessages } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next/useAnalyticsEvents';

import { useRouteDataRef } from '@confluence/route-manager';
import { TEMPLATE_GALLERY } from '@confluence/named-routes';
import { AppNavigationContext } from '@confluence/app-navigation-context';
import { createLazyCallbackHook } from '@confluence/loadable/entry-points/lazy-callback';

import type { PrimaryItemProps } from './PrimaryItem';
import { PrimaryItem } from './PrimaryItem';

const i18n = defineMessages({
	TemplatesLink: {
		id: 'app-navigation.templates.link',
		description: 'link to the Template Gallery page',
		defaultMessage: 'Templates',
	},
});

const useLazyClickAnalytics = createLazyCallbackHook(
	async () =>
		(await import(/* webpackChunkName: "loadable-analyticsCallbacks" */ './analyticsCallbacks'))
			.fireTemplatesClickedAnalytics,
);

export const TemplatesItem: FC<PrimaryItemProps> = memo(({ testId }) => {
	const routeDataRef = useRouteDataRef();
	const { resetStickySearchRef } = useContext(AppNavigationContext);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const fireClickedAnalytics = useLazyClickAnalytics(createAnalyticsEvent, routeDataRef);

	const onClick = useCallback(() => {
		resetStickySearchRef?.current?.();
		void fireClickedAnalytics();
	}, [resetStickySearchRef, fireClickedAnalytics]);

	return (
		<PrimaryItem
			testId={testId}
			i18n={i18n.TemplatesLink}
			namedRoute={TEMPLATE_GALLERY}
			onClick={onClick}
		/>
	);
});
