import { useCallback } from 'react';

import { getBooleanFF } from '@atlaskit/platform-feature-flags';

import { fg } from '@confluence/feature-gating';
import { isContentTypeEnabledInCurrentEnv } from '@confluence/content-types-utils';

import featureGates from './whiteboard-feature-gates.json';

export type WhiteboardFeatures = keyof typeof featureGates;

// These flags are no longer used in confluence and can be removed from this array entirely after we confirm that have been removed from the whiteboards side too
const ARCHIVED_FEATURE_FLAGS = [
	'headerV1',
	'headerV2',
	'whiteboardArchive',
	'removeWhiteboardsHome',
	'removeWhiteboardsHomeInSideNav',
	'whiteboardCreation',
	'whiteboardsEnabled',
] as const;
type ArchivedFeatureFlags = (typeof ARCHIVED_FEATURE_FLAGS)[number];
type ActiveFeatureGates = keyof typeof featureGates;

export const useIsWhiteboardFeatureEnabled = () => {
	const isWhiteboardsEnabledInEnv = isContentTypeEnabledInCurrentEnv('whiteboard');

	const isWhiteboardFeatureEnabled = useCallback(
		(featureName: ArchivedFeatureFlags | ActiveFeatureGates) => {
			if (featureName === 'whiteboardsEnabled') {
				return isWhiteboardsEnabledInEnv;
			}

			// If the FF has been cleaned up on our end just return true
			if (ARCHIVED_FEATURE_FLAGS.some((feature) => feature === featureName)) {
				return true;
			}

			if (featureName in featureGates) {
				const activeFeatureGate = featureName as ActiveFeatureGates;
				switch (activeFeatureGate) {
					case 'whiteboardSolidHeader':
						return fg('confluence_frontend_solid_whiteboard_header');
					case 'whiteboardVersionHistory':
						return fg('confluence_frontend_whiteboards_version_history_ui');
					case 'whiteboardOptOut':
						return fg('whiteboards_compliance_opt_out');
					case 'whiteboardBifrost':
						return fg('confluence_frontend_whiteboards_use_bifrost');
					case 'aiSmartButton':
						return fg('ai-smart-button-team-25');
					case 'digitalTeammate':
						return fg('cc-ai-whiteboards-enable-digital-teammate');
					default:
						throw new Error('unable to find feature gate in whiteboard-feature-gates.json');
				}
			}

			return false;
		},
		[isWhiteboardsEnabledInEnv],
	);

	const isWhiteboardCreationDropdownEnabled = useCallback((): boolean => {
		return (
			isWhiteboardFeatureEnabled('whiteboardsEnabled') &&
			!isWhiteboardFeatureEnabled('whiteboardOptOut')
		);
	}, [isWhiteboardFeatureEnabled]);

	const isWhiteboardGAOnboardingEnabled = useCallback(() => {
		return (
			isWhiteboardFeatureEnabled('whiteboardsEnabled') &&
			!isWhiteboardFeatureEnabled('whiteboardOptOut')
		);
	}, [isWhiteboardFeatureEnabled]);

	return {
		platformFeatureFlagResolver: getBooleanFF,
		isWhiteboardFeatureEnabled,
		isWhiteboardCreationDropdownEnabled,
		isWhiteboardGAOnboardingEnabled,
	};
};
