import type { FC } from 'react';
import React, { memo, useContext, useCallback } from 'react';
import { defineMessages } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next/useAnalyticsEvents';

import { useRouteDataRef, useRouteName } from '@confluence/route-manager';
import { WIKI, HOME } from '@confluence/named-routes';
import { AppNavigationContext } from '@confluence/app-navigation-context';
import { createLazyCallbackHook } from '@confluence/loadable/entry-points/lazy-callback';
import { GO_HOME_SHORTCUT } from '@confluence/shortcuts';
import { fg } from '@confluence/feature-gating';
import { useLivePageMode } from '@confluence/live-pages-utils/entry-points/useLivePagesStore';
import { HomeOnboardingTakeoverV2HomeNudge } from '@confluence/experiment-home-onboarding-takeover-v2/entry-points/HomeOnboardingTakeoverV2HomeNudge';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';

import type { PrimaryItemProps } from './PrimaryItem';
import { PrimaryItem } from './PrimaryItem';

const i18n = defineMessages({
	HomeLink: {
		id: 'app-navigation.home.link',
		description: 'link to the Home page',
		defaultMessage: 'Home',
	},
});

const useLazyClickAnalytics = createLazyCallbackHook(
	async () =>
		(await import(/* webpackChunkName: "loadable-analyticsCallbacks" */ './analyticsCallbacks'))
			.fireHomeClickedAnalytics,
);

export const HomeItem: FC<PrimaryItemProps> = memo(({ testId, pendoId }) => {
	const routeDataRef = useRouteDataRef();
	const { resetStickySearchRef } = useContext(AppNavigationContext);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const fireClickAnalytics = useLazyClickAnalytics(createAnalyticsEvent, routeDataRef);
	const handleClick = useCallback(() => {
		resetStickySearchRef?.current?.();
		void fireClickAnalytics();
	}, [fireClickAnalytics, resetStickySearchRef]);

	const isOnHomeOrWikiRouteArgs = {
		selector: (routeName: string | undefined) => {
			if (!routeName) {
				return false;
			}
			return routeName === WIKI.name || routeName === HOME.name;
		},
	};
	const isHighlighted = useRouteName(isOnHomeOrWikiRouteArgs);
	const [{ isEditMode: isLiveEditMode }] = useLivePageMode();
	const isOnEditRoute = useIsEditorPage();

	return (
		<HomeOnboardingTakeoverV2HomeNudge>
			<PrimaryItem
				testId={testId}
				i18n={i18n.HomeLink}
				namedRoute={HOME}
				keyboardShortcut={
					isLiveEditMode || (isOnEditRoute && fg('confluence_frontend_edit_page_keyboard_shortcut'))
						? undefined
						: GO_HOME_SHORTCUT
				}
				isAppNavigationShortcut
				onClick={handleClick}
				isHighlighted={isHighlighted}
				pendoId={pendoId}
			/>
		</HomeOnboardingTakeoverV2HomeNudge>
	);
});
